@font-face {
  font-family: MontserratThin;
  src: url(Assets/fonts/Montserrat-Thin.ttf);
}

@font-face {
  font-family: MontserratRegular;
  src: url(Assets/fonts/Montserrat-Regular.ttf);
}

html
{
  scroll-behavior: smooth
}
body {
  margin: 0 !important;
  padding: 0px !important;
  font-family: MontserratRegular, sans-serif, regular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  z-index:2;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout-footer
{
  padding: 0px !important;
}
